import dchAPI from '@/services/dchAPI';

const state = {
  tags: [],
};

const getters = {
  allTags: (state) => state.tags.sort((a, b) => a.name.localeCompare(b.name)),
  tagsBySource: (state) => (source) => {
    return state.tags.filter((tag) => tag.sources === undefined || tag.sources.includes(source));
  },
  getTag: (state) => (id) => {
    return state.tags.find((tag) => tag.id === id);
  },
};

const actions = {
  async fetchTagsData({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('tags')
        .then((response) => {
          const responseData = response.data.data.map((r) => transformTagsResponseData(r));
          commit('setTagsData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchTagsBySource({ commit }, source) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`tags?source=${source}`)
        .then((response) => {
          const responseData = response.data.data.map((r) => transformTagsResponseData(r));
          commit('setTagsData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setTagsData: (state, tags) => {
    state.tags = tags;
  },
};

const transformTagsResponseData = (data) => {
  return {
    ...data,
    id: data.handle,
    active: false,
    clicked: false,
    not_connected: false,
    central: false,
    hidden: false,
    show_only_connected_to: false,
  };
};

export default {
  state,
  getters,
  actions,
  mutations,
};
