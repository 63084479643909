import { createToaster } from '@meforma/vue-toaster';

const toaster = createToaster({
  duration: 10000,
  dismissible: true,
  position: 'top',
});

export default {
  methods: {
    htmlize(message) {
      return `<div style="display:flex;justify-content: space-between;">
        <div>${message}</div>
        <div style="width:50px;"></div>
        <div>OK</div>
      </div>`;
    },
    successToaster(message) {
      toaster.success(this.htmlize(message));
    },
    warningToaster(message) {
      toaster.warning(this.htmlize(message));
    },
  },
};
