import LocalStorageService from '@/services/localStorageService';
import platformAPI from '@/services/platformAPI';
import dchAPI from '@/services/dchAPI';

const state = {
  token: LocalStorageService.getAccessToken() || null,
  tokenExpiry: LocalStorageService.getTokenExpiry() || null,
  refreshToken: LocalStorageService.getRefreshToken() || null,
  userEmail: LocalStorageService.getUserEmail() || '',
  hasLoggedIn: LocalStorageService.getHasLoggedIn() || null,
  company_id: null,
  authIdToken: null,
  authAccessToken: null,
  authRefreshToken: null,
  authChallenge: null,
  authResetResponse: null,
};

const getters = {
  isLoggedIn(state) {
    const { token } = state;
    return !!token;
  },
  hasLoggedIn(state) {
    return state.hasLoggedIn;
  },
  getUserEmail(state) {
    return state.userEmail;
  },
  isAuthenticated: (state) => !!state.authIdToken,
  authChallenge: (state) => state.authChallenge,
  authResetResponse: (state) => state.authResetResponse,
};

const actions = {
  logout(context) {
    if (context.getters.isLoggedIn) {
      context.commit('logout');
      context.commit('clearAuth');
    }
  },
  refreshLogin(context, { token, refreshToken }) {
    context.commit('refreshLogin', {
      token,
      refreshToken,
    });
  },
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      const email = credentials.email;
      platformAPI
        .post('login', {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          if (!response.data.dch) {
            let error = new Error('Ankeri DCH rights are required');
            error.status = 403;
            error.response = { data: { message: 'Ankeri DCH rights are required' } };
            throw error;
          }

          const { token, refreshToken, exp: tokenExpiry, dchCompanyId: companyId } = response.data;

          context.commit('login', {
            token,
            tokenExpiry,
            refreshToken,
            email,
            companyId,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startlogin(context, credentials) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post('/auth/login', {
          username: credentials.username,
          password: credentials.password,
        })
        .then((response) => {
          const login_response = response.data.data;
          // important here to check if the response is a challenge first
          if (login_response.ChallengeName) {
            context.commit('setAuthChallenge', login_response);
          } else if (login_response.AuthenticationResult) {
            context.commit('setAuthTokens', login_response.AuthenticationResult);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  respondToAuthChallenge(context, challengeResponse) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post('/auth/challenge', challengeResponse)
        .then((response) => {
          const login_response = response.data.data;
          // important here to check if the response is a challenge first
          if (login_response.ChallengeName) {
            context.commit('setAuthChallenge', login_response);
          } else if (login_response.AuthenticationResult) {
            context.commit('setAuthTokens', login_response.AuthenticationResult);
          } else if (login_response.token) {
            const {
              email,
              token,
              refreshToken,
              exp: tokenExpiry,
              dchCompanyId: companyId,
            } = login_response;
            context.commit('setAuthLogin', {
              token,
              tokenExpiry,
              refreshToken,
              email,
              companyId,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshAuth(context, { token, tokenExpiry }) {
    context.commit('setAuthRefresh', {
      token,
      tokenExpiry,
    });
  },
  resetPassword(context, input) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post('/auth/reset', input)
        .then((response) => {
          context.commit('authResetResponse', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  confirmResetPassword(context, input) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post('/auth/reset/confirm', input)
        .then((response) => {
          context.commit('authResetResponse', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  login(state, { token, tokenExpiry, refreshToken, email, companyId }) {
    state.token = token;
    state.refreshToken = refreshToken;
    state.userEmail = email;
    state.tokenExpiry = tokenExpiry;
    state.companyId = companyId;
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setTokenExpiry(tokenExpiry);
    LocalStorageService.setRefreshToken(refreshToken);
    LocalStorageService.setUserEmail(email);
    LocalStorageService.setHasLoggedIn(true);
    LocalStorageService.setDchCompanyId(companyId);
  },
  refreshLogin(state, { token, refreshToken, tokenExpiry }) {
    state.token = token;
    state.refreshToken = refreshToken;
    state.tokenExpiry = tokenExpiry;
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setRefreshToken(refreshToken);
    LocalStorageService.setTokenExpiry(tokenExpiry);
  },
  logout(state) {
    state.token = null;
    state.userEmail = null;
    state.refreshToken = null;
    state.tokenExpiry = null;
    LocalStorageService.deleteAccessToken();
    LocalStorageService.deleteRefreshToken();
    LocalStorageService.deleteTokenExpiry();
    LocalStorageService.deleteUserEmail();
  },
  setAuthTokens(state, tokens) {
    state.authIdToken = tokens.IdToken;
    state.authAccessToken = tokens.AccessToken;
    state.authRefreshToken = tokens.RefreshToken;
    state.authResetResponse = null;
    localStorage.setItem('authIdToken', tokens.IdToken);
    localStorage.setItem('authAccessToken', tokens.AccessToken);
    localStorage.setItem('authRefreshToken', tokens.RefreshToken);
  },
  setAuthChallenge(state, challenge) {
    state.authChallenge = challenge;
  },
  setAuthLogin(state, { token, tokenExpiry, refreshToken, email, companyId }) {
    state.token = token;
    state.authRefreshToken = refreshToken;
    state.userEmail = email;
    state.tokenExpiry = tokenExpiry;
    state.hasLoggedIn = true;
    state.authChallenge = null;
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setUserEmail(email);
    LocalStorageService.setHasLoggedIn(true);
    LocalStorageService.setTokenExpiry(tokenExpiry);
    // The refresh token is from Cognito, so we store it as authRefreshToken
    localStorage.setItem('authRefreshToken', refreshToken);
    LocalStorageService.setDchCompanyId(companyId);
  },
  setAuthRefresh(state, { token, tokenExpiry }) {
    state.token = token;
    state.tokenExpiry = tokenExpiry;
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setTokenExpiry(tokenExpiry);
  },
  clearAuth(state) {
    state.authIdToken = null;
    state.authAccessToken = null;
    state.authRefreshToken = null;
    state.authChallenge = null;
    state.authResetResponse = null;
    localStorage.removeItem('authIdToken');
    localStorage.removeItem('authAccessToken');
    localStorage.removeItem('authRefreshToken');
    LocalStorageService.deleteDchCompanyId();
  },
  resetResponse(state, response) {
    state.authResetResponse = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
