import dchAPI from '@/services/dchAPI';

const state = {
  currentFetchRequests: [],
};

const getters = {
  currentFetchRequests: (state) => state.currentFetchRequests,
};

const actions = {
  async refetchData({ commit }, model) {
    const requestBody = {
      source_handle: model.source_handle,
      start: model.start_time,
      end: model.end_time,
      asset_ids: model.assets.map((asset) => asset.asset_id),
    };

    return new Promise((resolve, reject) => {
      dchAPI
        .post('data/fetch', requestBody)
        .then((response) => {
          commit('setCurrentFetchRequests', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async pollByFetchId({ commit }, fetchId) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`data/fetch/${fetchId}`)
        .then((response) => {
          commit('setCurrentFetchRequest', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async fetchRequestSummary({ commit }, model) {
    // start=2023-01-01T00%3A00%3A00&end=2024-01-09T06%3A18%3A00&interval=monthly
    var params = {
      interval: model.interval,
      start: model.start_time,
      end: model.end_time,
    };
    if (model.asset_id != null) {
      params.asset_id = model.asset_id;
    }
    if (model.source_handle != null) {
      params.source = model.source_handle;
    }
    return new Promise((resolve, reject) => {
      dchAPI
        .get('data/fetch/summary', { params: params })
        .then((response) => {
          commit('setFetchRequestSummary', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setCurrentFetchRequests: (state, fetchRequests) => {
    state.currentFetchRequests = fetchRequests;
  },
  setCurrentFetchRequest: (state, fetchRequest) => {
    const index = state.currentFetchRequests.findIndex(
      (req) => req.fetch_id === fetchRequest.fetch_id
    );
    if (index > -1) {
      state.currentFetchRequests[index] = fetchRequest;
    }
  },
  setFetchRequestSummary: (state, fetchRequestSummary) => {
    state.fetchRequestSummary = fetchRequestSummary;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
