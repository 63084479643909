export const demoConfig = [
  {
    name: 'MAC Online System',
    id: 'marorka',
    logo: './mac_system_solutions.png',
    background_color: 'white',
    is_enabled: false,
    type: 'autologged',
  },
  {
    name: 'Marorka',
    id: 'mac_autolog',
    logo: './marorka.jpeg',
    background_color: 'surface',
    is_enabled: false,
    type: 'autologged',
  },
];

export const demoAssets = [
  { imo: '9525900', name: 'ABUYE' },
  { imo: '9708837', name: 'AUGUSTA' },
  { imo: '9622019', name: 'BEAR' },
  { imo: '9708849', name: 'HANDLES' },
  { imo: '9349540', name: 'LUIGI' },
  { imo: '9708875', name: 'NEVADO' },
  { imo: '9732345', name: 'REGAL' },
  { imo: '9349497', name: 'STANLEY' },
  { imo: '9732307', name: 'VERMILION' },
  { imo: '9708825', name: 'WINDOM' },
  { imo: '9777606', name: 'MORGAN' },
  { imo: '9311787', name: 'OMBIGAICHAN' },
  { imo: '9307279', name: 'NUMBUR' },
  { imo: '9238765', name: 'KANCHENJUNGA' },
  { imo: '9349514', name: 'DUWONI' },
  { imo: '9525883', name: 'GADA' },
  { imo: '9525869', name: 'AMALTITAN' },
  { imo: '9732319', name: 'AVALANCHE' },
  { imo: '9635640', name: 'CRESTONE' },
  { imo: '9309409', name: 'OM PARVAT' },
  { imo: '9612789', name: 'BLACKBURN' },
  { imo: '9612997', name: 'SLAGGARD' },
];
