// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/dch_login.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--app-background-color:#f5f5f5;--box-background-color:#fff;--title-color:#363636;--subtitle-color:#4a4a4a;--box-background-color-inverse:#ddd;--title-color-inverse:#363636;--box-border-color:blue;--box-border-connected-color:blue}[theme=dark]{--app-background-color:#091a28;--box-background-color:#213749;--title-color:#fff;--subtitle-color:cyan;--box-background-color-inverse:#091a28;--title-color-inverse:#fff;--box-border-color:#ff0;--box-border-connected-color:#ff0}body,html{height:100%}html{background-color:var(--app-background-color)!important}#app{background-color:var(--app-background-color)}.dynamic-title{margin-bottom:20px;height:20px}.dynamic-container{margin:20px}.dynamic-box,.dynamic-tag{color:var(--title-color);background-color:var(--box-background-color)}.dynamic-box{border-style:solid;border-color:var(--box-background-color);border-width:1px}.dynamic-tag-inverse{color:var(--title-color-inverse);background-color:var(--box-background-color-inverse)}.dynamic-box{padding-top:10px;padding-bottom:10px}.skeleton-card{margin-top:15px}input.theme-switch+label,input.theme-switch+label:active,input.theme-switch:checked+label,input.theme-switch:not(:checked)+label:hover{cursor:pointer}.v-container--fluid{max-width:1344px!important}.logged-out{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:100%}.btn-container{display:flex;justify-content:flex-end}.v-badge__badge .v-icon{color:rgb(var(--v-theme-error))!important}.tooltip-content{background-color:rgba(var(--v-theme-surface-variant),.9)!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
