import { v4 as uuid } from 'uuid';
import dchAPI from '@/services/dchAPI';

const state = {
  timeseriesData: [],
};

const getters = {
  allTimeseriesData: (state) => state.timeseriesData,
};

const actions = {
  async fetchTimeseriesData({ commit }, urlPostfix) {
    await getData(urlPostfix, commit).then((data) => {
      commit('setTimeseriesData', data);
    });
  },
};

function getData(urlPostfix, cursor, data = []) {
  let cursorString = typeof cursor === 'string' ? `&cursor=${cursor}` : '';
  return dchAPI.get('timeseries' + urlPostfix + cursorString).then((response) => {
    let transformedData = response.data.data.map(
      (r) => (r = { ...r, asset_id: r.asset_id.toString()})
    );
    data.push(...transformedData);

    if (!response.data.meta.cursor) {
      return data;
    }

    return getData(urlPostfix, response.data.meta.cursor, data);
  });
}

const mutations = {
  setTimeseriesData: (state, timeseriesData) => {
    state.timeseriesData = timeseriesData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
