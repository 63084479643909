<template>
  <v-app>
    <v-main :class="{ 'logged-out': !loggedIn }">
      <AppHeader v-if="loggedIn" />
      <AppHeaderNotLoggedIn v-else />
      <v-container fluid>
        <router-view />
      </v-container>

      <div class="btn-container">
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          variant="elevated"
          rounded="pill"
          density="comfortable"
          size="x-large"
          class="pa-0 ma-4"
          min-width="56"
          height="56"
          color="blue"
          fab
          dark
          @click="toTop"
        >
          <v-icon icon="mdi-arrow-up" />
        </v-btn>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import images from '@/mixins/images';
import AppHeaderNotLoggedIn from '@/components/AppHeaderNotLoggedIn';
import toasters from '@/mixins/toasters';
import eventBus from './common/EventBus';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app',
  mixins: [images, toasters],
  components: {
    AppHeader,
    AppHeaderNotLoggedIn,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    loggedIn() {
      return this.isLoggedIn;
    },
  },
  data: () => ({
    fab: false,
  }),
  mounted() {
    eventBus.on('logout', () => {
      this.logOut();
    });
    this.checkAuth().catch(() => {
      this.logOut();
    });
  },
  methods: {
    ...mapActions(['checkAuth']),
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    logOut() {
      this.$router.push({ name: 'logout' });
    },

    beforeDestroy() {
      eventBus.remove('logout');
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/styles.scss';
.v-container--fluid {
  max-width: 1344px !important;
}
.logged-out {
  background-image: url('assets/dch_login.png');
  background-size: cover;
  background-position: right;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
}
.v-badge__badge .v-icon {
  color: rgb(var(--v-theme-error)) !important;
}
.tooltip-content {
  background-color: rgba(var(--v-theme-surface-variant), 0.9) !important;
}
</style>
