import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/dchapi/' : process.env.VUE_APP_DCH_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
