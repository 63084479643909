import axios from 'axios';
import axiosInstance from './dchAPI';
import LocalStorageService from './localStorageService';
import eventBus from '../common/EventBus';

const no_token_urls = ['login', 'auth/login'];
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!no_token_urls.includes(config.url)) {
        config.headers['authorization'] = LocalStorageService.getAccessToken();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (!no_token_urls.includes(originalConfig.url) && err.response) {
        const errorMessage = err.response.data?.message ?? err.response.data;
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          errorMessage === 'Unauthorized'
        ) {
          originalConfig._retry = true;

          const authRefreshToken = localStorage.getItem('authRefreshToken');
          const platformRefreshToken = LocalStorageService.getRefreshToken();
          if (authRefreshToken) {
            try {
              const dchUrl =
                process.env.NODE_ENV === 'development'
                  ? '/dchapi/'
                  : process.env.VUE_APP_DCH_API_URL;
              const res = await axios.post(`${dchUrl}auth/refresh`, {
                refreshToken: authRefreshToken,
                ActAsCompanyId: LocalStorageService.getDchCompanyId(),
              });

              const { token, exp: tokenExpiry } = res.data.data;

              store.dispatch('refreshAuth', {
                token,
                tokenExpiry,
              });

              return axiosInstance(originalConfig);
            } catch (_error) {
              eventBus.dispatch('logout');
              return Promise.reject(_error);
            }
          } else if (platformRefreshToken) {
            try {
              const platformUrl =
                process.env.NODE_ENV === 'development'
                  ? '/dchapi/'
                  : process.env.VUE_APP_PLATFORM_API_URL;
              const res = await axios.post(`${platformUrl}login/refresh`, {
                refreshToken: LocalStorageService.getRefreshToken(),
              });

              const { token, refreshToken } = res.data;

              store.dispatch('refreshLogin', { token, refreshToken });

              return axiosInstance(originalConfig);
            } catch (_error) {
              eventBus.dispatch('logout');
              return Promise.reject(_error);
            }
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
