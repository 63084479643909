import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? '/platformapi/' : process.env.VUE_APP_PLATFORM_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
