import dchAPI from '@/services/dchAPI';

const state = {
  validationAlerts: [],
  validationAlertsCursor: null,
  validationProfiles: [],
  validators: [],
  validationSummary: null,
  validationSummaryShowSources: false,
};

const getters = {
  validationAlerts: (state) => state.validationAlerts,
  validationAlertsCursor: (state) => state.validationAlertsCursor,
  validationProfiles: (state) => state.validationProfiles,
  validators: (state) => state.validators,
  validationSummary: (state) => state.validationSummary,
  validationSummaryShowSources: (state) => state.validationSummaryShowSources,
  getValidationProfile: (state) => (id) => {
    const traverseValidationProfiles = (profiles, id) => {
      for (const profile of profiles) {
        if (profile.profile_id === id) {
          return profile;
        }
        if (profile.children && profile.children.length > 0) {
          const result = traverseValidationProfiles(profile.children, id);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    return traverseValidationProfiles(state.validationProfiles, id);
  },
};

const actions = {
  async fetchValidationSummary({ commit }, urlPostfix) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('validations/summary' + urlPostfix)
        .then((response) => {
          commit('setValidationSummary', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchValidationAlerts({ commit }, urlPostfix, cursor) {
    return new Promise((resolve, reject) => {
      let cursorString = typeof cursor === 'string' ? `&cursor=${cursor}` : '';
      dchAPI
        .get('validations/results' + cursorString)
        .then((response) => {
          commit('setValidationAlerts', response.data.data);
          commit('setValidationAlertsCursor', response.data.meta.cursor);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchValidationProfiles({ commit }, includeArchived = false) {
    return new Promise((resolve, reject) => {
      let includeArchivedString = includeArchived ? '?include_archived=true' : '';
      dchAPI
        .get('validations/profiles' + includeArchivedString)
        .then((response) => {
          commit('setValidationProfiles', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchValidators({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('validations/validators')
        .then((response) => {
          commit('setValidators', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteValidationProfile({ commit }, id) {
    return new Promise((resolve, reject) => {
      dchAPI
        .delete(`validations/profiles/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async undeleteValidationProfile({ commit }, id) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post(`validations/profiles/${id}/restore`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createValidationProfile({ commit }, profile) {
    return new Promise((resolve, reject) => {
      dchAPI
        .post('validations/profiles', profile)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateValidationProfile({ commit }, profile) {
    let p = Object.fromEntries(
      Object.entries(profile).filter(([n, v]) => n != 'children' && n != 'audit_trail' && v != null)
    );
    return new Promise((resolve, reject) => {
      dchAPI
        .put(`validations/profiles/${profile.profile_id}`, p)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setValidationAlerts: (state, validationAlerts) => {
    state.validationAlerts = validationAlerts;
  },
  setValidationAlertsCursor: (state, validationAlertsCursor) => {
    state.validationAlertsCursor = validationAlertsCursor;
  },
  setValidationProfiles: (state, validationProfiles) => {
    state.validationProfiles = validationProfiles;
  },
  setValidators: (state, validators) => {
    state.validators = validators;
  },
  setValidationSummary: (state, validationSummary) => {
    state.validationSummary = validationSummary;
  },
  setValidationSummaryShowSources: (state, showSources) => {
    state.validationSummaryShowSources = showSources;
  },
  toggleValidationSummaryShowSources: (state) => {
    state.validationSummaryShowSources = !state.validationSummaryShowSources;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
