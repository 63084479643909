import { createStore } from 'vuex';
import initial from './modules/initial';
import sources from './modules/sources';
import outcomes from './modules/outcomes';
import assets from './modules/assets';
import tags from './modules/tags';
import config from './modules/config';
import authentication from './modules/authentication';
import data from './modules/data';
import refetch from './modules/refetch';
import validations from './modules/validations';

// Create store
const store = createStore({
  modules: {
    initial,
    sources,
    outcomes,
    assets,
    tags,
    config,
    authentication,
    data,
    refetch,
    validations,
  },
});

export default store;
