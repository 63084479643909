export default {
  methods: {
    getSourceLogoImg(path) {
      return require.context('@/assets/source_logos/')(path);
    },
    getImg(path) {
      return require.context('@/assets/')(path);
    },
  },
};
