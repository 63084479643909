class LocalStorageService {
  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  setAccessToken(token) {
    localStorage.setItem('access_token', token);
  }

  deleteAccessToken() {
    localStorage.removeItem('access_token');
  }

  getTokenExpiry() {
    return localStorage.getItem('access_token_expiry');
  }

  setTokenExpiry(tokenExpiry) {
    localStorage.setItem('access_token_expiry', tokenExpiry);
  }

  deleteTokenExpiry() {
    localStorage.removeItem('access_token_expiry');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  setRefreshToken(token) {
    localStorage.setItem('refresh_token', token);
  }

  deleteRefreshToken() {
    localStorage.removeItem('refresh_token');
  }

  getHasLoggedIn() {
    return JSON.parse(localStorage.getItem('has_logged_in') || 'false');
  }

  setHasLoggedIn(hasLoggedIn) {
    localStorage.setItem('has_logged_in', JSON.stringify(hasLoggedIn));
  }

  getUserEmail() {
    return localStorage.getItem('user_email');
  }

  setUserEmail(email) {
    localStorage.setItem('user_email', email);
  }

  deleteUserEmail() {
    localStorage.removeItem('user_email');
  }

  getDchCompanyId() {
    return localStorage.getItem('dch_company_id');
  }

  setDchCompanyId(dchCompanyId) {
    localStorage.setItem('dch_company_id', dchCompanyId);
  }

  deleteDchCompanyId() {
    localStorage.removeItem('dch_company_id');
  }

  getDataFilter() {
    return JSON.parse(localStorage.getItem('data_filter'));
  }

  setDataFilter(filter) {
    localStorage.setItem('data_filter', JSON.stringify(filter));
  }

  deleteDataFilter() {
    localStorage.removeItem('data_filter');
  }

  getDataConfig() {
    let config = JSON.parse(localStorage.getItem('data_config'));
    if (config == null) {
      config = { filter: true, chart: true, timeline: true };
    }
    return config;
  }

  setDataConfigPanelState(panel, value) {
    const config = this.getDataConfig();
    config[panel] = value;
    localStorage.setItem('data_config', JSON.stringify(config));
  }

  deleteDataConfig() {
    localStorage.removeItem('data_config');
  }

  getIsDarkMode() {
    const isDarkMode = JSON.parse(localStorage.getItem('dark_mode'));

    // We only check the users color scheme preference if the local storage variable is null,
    // in which case the user hasn't defined his in app preference.
    return (
      isDarkMode ||
      (isDarkMode === null && window.matchMedia('(prefers-color-scheme: dark)').matches)
    );
  }

  setIsDarkMode(isDark) {
    localStorage.setItem('dark_mode', isDark);
  }

  getMemorized(name) {
    let memorized = JSON.parse(localStorage.getItem('memorized'));
    if (memorized == null) {
      memorized = {};
    }
    if (name != null) {
      return memorized[name];
    }
    return memorized;
  }

  setMemorized(name, value) {
    const memorized = this.getMemorized(null);
    memorized[name] = value;
    localStorage.setItem('memorized', JSON.stringify(memorized));
  }
}

export default new LocalStorageService();
