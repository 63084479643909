import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import routes from './routes';
import store from './store';

import dchSetupInterceptors from './services/dchSetupInterceptors';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import vuetify from './plugins/vuetify';

dchSetupInterceptors(store);

/* Vue.config.productionTip = false; */

const router = createRouter({
  routes,
  history: createWebHistory(),
  /* scrollBehavior() {
    // Scroll to top when changing route
    return { x: 0, y: 0 };
  }, */
});

router.beforeEach((to, from) => {
  store.dispatch('startRouteLoading');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn && store.getters.hasLoggedIn) {
      return {
        name: 'login',
      };
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      return {
        name: 'connections',
      };
    }
  }
});

router.afterEach(() => {
  store.dispatch('stopRouteLoading');
});

const app = createApp(App).use(vuetify).use(router).use(store);

if (process.env.NODE_ENV == 'production' && true == false) {
  Sentry.init({
    app,
    dsn: 'https://e143af23f7454675ba9649fd5e8f53db@o274574.ingest.sentry.io/5668503',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

app.mount('#app');
