<template>
  <v-app-bar app color="transparent" flat>
    <v-container class="py-0 fill-height">
      <!-- Ankeri logo -->
      <v-btn router :to="'/'" color="transparent">
        <img
          :src="getImg('./logo_ankeri.svg')"
          alt="Ankeri Data Connections Hub"
          class="ankeri-logo"
        />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import images from '@/mixins/images';

export default {
  name: 'AppHeaderNotLoggedIn',
  mixins: [images],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');
    // check for active theme
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark');
      this.darkMode = true;
    } else {
      htmlElement.setAttribute('theme', 'light');
      this.darkMode = false;
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-left: 10px;
  margin-right: 10px;
}

#no-background::before {
  background-color: transparent !important;
}

.avatar-item {
  display: float;
  top: 3px;
  left: 18px;
}

.img {
  width: 26px;
}
.ankeri-logo {
  width: 100px;
}
.v-container {
  max-width: 1344px !important;
}
</style>
