const routes = [
  {
    path: '/',
    name: 'connections',
    component: () => import('./views/Connections.vue'),
    meta: {
      title: 'Ankeri DCH | Connections',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
    meta: {
      title: 'Ankeri DCH | Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('./views/AdminLogin.vue'),
    meta: {
      title: 'Ankeri DCH | Admin',
      requiresVisitor: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/Logout.vue'),
    meta: {
      title: 'Ankeri DCH',
      requiresAuth: true,
    },
  },
  {
    path: '/data',
    name: 'data',
    component: () => import('./views/Data.vue'),
    meta: {
      title: 'Ankeri DCH | Data',
      requiresAuth: true,
    },
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('./views/Alerts.vue'),
    meta: {
      title: 'Ankeri DCH | Alerts',
      requiresAuth: true,
    },
  },
  {
    path: '/sources',
    name: 'sources',
    component: () => import('./views/Sources.vue'),
    meta: {
      title: 'Ankeri DCH | Sources',
      requiresAuth: true,
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import('./views/Assets.vue'),
    meta: {
      title: 'Ankeri DCH | Assets',
      requiresAuth: true,
    },
  },
  {
    path: '/sources/:id',
    name: 'sourceItem',
    component: () => import('./components/SourceItem.vue'),
    props: true,
    meta: {
      title: 'Ankeri DCH | Source',
      requiresAuth: true,
    },
  },
  {
    path: '/assets/:id',
    name: 'assetItem',
    component: () => import('./components/AssetItem.vue'),
    props: true,
    meta: {
      title: 'Ankeri DCH | Asset',
      requiresAuth: true,
    },
  },
  {
    path: '/outcomes',
    name: 'outcomes',
    component: () => import('./views/Outcomes.vue'),
    meta: {
      title: 'Ankeri DCH | Outcomes',
      requiresAuth: true,
    },
  },
  {
    path: '/outcomes/:id',
    name: 'outcomeItem',
    component: () => import('./components/OutcomeItem.vue'),
    props: true,
    meta: {
      title: 'Ankeri DCH | Outcome',
      requiresAuth: true,
    },
  },
  {
    path: '/api',
    name: 'api',
    beforeEnter() {
      window.open(process.env.VUE_APP_API_DOCS_URL, '_blank');
      return false;
    },
    meta: {
      title: 'Ankeri DCH | Ankeri Outcome API',
    },
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('./views/Config.vue'),
    meta: {
      title: 'Ankeri DCH | Config',
      requiresAuth: true,
    },
  },
];

export default routes;
