import dchAPI from '@/services/dchAPI';
import configService from '@/services/configService';

const state = {
  config: {},
};

const getters = {
  allConfig: (state) => state.config,
};

const actions = {
  async fetchConfig({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('config')
        .then((response) => {
          const responseData = transformConfigResponseData(response.data.data);
          commit('setConfigData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setConfigData: (state, config) => {
    state.config = config;
  },
};

const transformConfigResponseData = (data) => {
  return {
    outcomes: {
      endpoints: data.outcomes.endpoints.map((d) =>
        configService.availableAPITypes().find((type) => type.id === d)
      ),
    },
    available_sources: data.available_sources.map((source) => {
      return {
        ...source,
        ...configService.availableSources().find((s) => s.id === source.source_handle),
      };
    }),
  };
};

export default {
  state,
  getters,
  actions,
  mutations,
};
