import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import localStorageService from '@/services/localStorageService';

const customLightTheme = {
  dark: false,
  colors: {
    background: '#f5f5f5',
    'background-darken-1': '#cccccc',
    surface: '#FFFFFF',
    text: '#212121',
    primary: '#1976d2',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#EB5358',
    'error-text': '#EB5358',
    info: '#2196F3',
    success: '#4CAF50',
    'success-text': '#4CAF50',
    warning: '#FB8C00',
  },
};

const customDarkTheme = {
  dark: true,
  colors: {
    background: '#303030',
    'background-darken-1': '#606060',
    surface: '#000000',
    text: '#dedede',
    error: '#eb5358',
    'error-text': '#ff777c',
    'success-text': '#3bec3b',
  },
};

const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: localStorageService.getIsDarkMode() ? 'customDarkTheme' : 'customLightTheme',
    themes: {
      customLightTheme,
      customDarkTheme,
    },
  },
});

export default vuetify;
